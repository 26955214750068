<template>
	<section class="section-bottom">
		<div class="wrapper">
			<div class="video-wrapper">
				<video autoplay muted loop>
					<source src='../assets/images/game-night.mp4' type='video/mp4'>
				</video>
			</div>
			<div class="bottom-wrapper">
				<h2>TIME TO SPAWN INTO <br><span>THE MV VERSE</span> !</h2>
				<action-button class="btn-wrapper" />
			</div>
			<div class="gradient"></div>
		</div>
	</section>
</template>
<script>
import ActionButton from '@/components/ActionButton'

export default {
  name:"SectionBottom",
  components: { 
    ActionButton,
  },
}
</script>
<style scoped>
	.gaming-lander-wrapper .section-bottom {
		padding-top: 130px;
	}
	.section-bottom {
		overflow: hidden;
	}
	.section-bottom .wrapper {
		position: relative;
	}
	.section-bottom .btn-wrapper {
		display: flex;
		justify-content: center;
	}
	.section-bottom h2 {
		text-align: center;
		font-size: 30px;
		line-height: 40px;
		font-weight: bold;
		font-style: normal;
		padding: 0 25px;
	}
	.section-bottom h2 span {
		color: #21D6D9;
	}
	.section-bottom .video-wrapper {
		display: flex;
    justify-content: center;
		opacity: 0.3;
	}
	.section-bottom .video-wrapper video {
    width: 250%;
	}
	.section-bottom .bottom-wrapper {
		margin-top: -340px;
    position: absolute;
		z-index: 2;
		width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    width: stretch;
	}
	.gradient {
		height: 400px;
    width: 120%;
    margin-top: -394px;
		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#0f0f12+60,0f0f12+99&0+0,1+100 */
		background: -moz-linear-gradient(top,  rgba(15,15,18,0) 0%, rgba(15,15,18,0.6) 60%, rgba(15,15,18,0.99) 99%, rgba(15,15,18,1) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  rgba(15,15,18,0) 0%,rgba(15,15,18,0.6) 60%,rgba(15,15,18,0.99) 99%,rgba(15,15,18,1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  rgba(15,15,18,0) 0%,rgba(15,15,18,0.6) 60%,rgba(15,15,18,0.99) 99%,rgba(15,15,18,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    position: absolute;
    overflow: hidden;
    margin-left: -30px;
	}
	/* tablet style */
	@media (min-width: 768px) {
		.section-bottom .bottom-wrapper {
			margin-left: auto;
			padding: 0 157px;
			margin-right: auto;
		}
		.section-bottom .video-wrapper video {
			width: 140%;
		}
	}
	/* small screens style */
	@media (min-width: 1024px) {
		.section-bottom .bottom-wrapper {
			margin-top: -450px;
		}		
		.section-bottom h2 {
			font-size: 50px;
			line-height: 63px;
		}				
	}
	/* large screens style */
	@media (min-width: 1200px) {
		.section-bottom .bottom-wrapper {
			margin-top: -450px;
		}
		.section-bottom h2 {
			font-size: 70px;
			line-height: 86px;
			padding: 0 60px;
		}		
	}
	/* xlarge screens style */
	@media (min-width: 1920px) {
		.section-bottom .bottom-wrapper {
			margin-top: -625px;
			text-align: center;
		}
		.section-bottom h2 {
			font-size: 90px;
			line-height: 110px;
			padding: 0 320px;
		}
	}		
</style>