<template>
	<section class="section-top">
		<p>MANYVIDS</p>
		<div>
			<h1>MAKE YOUR WAY TO THE TOP AND WIN <span>$$$ PRIZES</span> !</h1>
			<p>Stop being at the mercy of complex algorithms. Join one of the fastest-growing, 
					judgement-free gaming communities for adults and share your content with an audience of 4 million members!</p>
			<h2><font-awesome-icon class="icon" icon="fa fa-calendar" /> FRI, JUNE 3<sup>RD</sup></h2>
		</div>
		<action-button />
		<div class="img-wrapper">
			<img src="../assets/images/girl-big.png"/>
		</div>
	</section>
</template>
<script>
import ActionButton from '@/components/ActionButton'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faCalendar)

export default {
  name:"SectionTop",
  components: { 
    ActionButton,
		'font-awesome-icon': FontAwesomeIcon
  },
}
</script>
<style scoped>
	.section-top {
		overflow: hidden;
	}
	.section-top p:first-child {
    color: #B53BFE;
    margin-top: 0;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 0.6px;
	}
	.section-top h1 {
    padding-right: 15px;
		font-weight: bold;
		font-style: normal;
	}
	.section-top h2 .icon {
		margin-right: 15px;
	}
	.section-top h2 sup {
		font-size: small;
	}
	.section-top p {
		font-size: 18px;
		line-height: 21px;
		font-weight: normal;
	}
	.section-top h2 {
		margin-bottom: 40px;
		font-size: 22px;
		line-height: 25px;
		font-style: normal;
		font-weight: lighter;
	}
	.section-top h1 span, .section-top h2 {
		color: #21D6D9;
	}
	.section-top .img-wrapper {
		width: 1309px;
	}
	.section-top .img-wrapper img {
		width: 42%;
		margin-top: -30px;
	}
	/* tablet and small screens style */
	@media (min-width: 768px) {
		.gaming-lander-wrapper .section-top {
			padding-left: 80px;
		}
		.section-top h1 {
			width: 450px;
		}
		.section-top p {
			width: 450px;
		}
		.section-top .img-wrapper img {
			margin-top: -148px;
			margin-left: 239px;
		}
	}
	/* small screens style */
	@media (min-width: 1024px) {
		.gaming-lander-wrapper .section-top {
			padding-left: 156px;
		}
		.section-top .img-wrapper img {
			width: 50%;
			margin-top: -62px;
			margin-left: 380px;
		}
		.section-top h1 {
			width: 650px;
			font-size: 50px;
			line-height: 55px;
		}
		.section-top p {
			width: 650px;
			font-size: 20px;
			line-height: 24px;
		}
		.section-top h2 {
			width: 650px;
			font-size: 23px;
			line-height: 26px;		
		}
		.section-top .img-wrapper img {
			width: 55%;
			margin-top: -226px;
			margin-left: 383px;
		}
	}
	/* large screens style */
	@media (min-width: 1200px) {
		.gaming-lander-wrapper .section-top {
			padding-left: 233px;
		}		
		.section-top h1 {
			width: 650px;
			font-size: 70px;
			line-height: 75px;
		}
		.section-top p {
			width: 650px;
			font-size: 22px;
			line-height: 26px;
		}
		.section-top h2 {
			width: 650px;
			font-size: 24px;
			line-height: 28px;	
		}
		.section-top .img-wrapper img {
			width: 72%;
			margin-top: -226px;
			margin-left: 383px;
		}		
	}
	/* xlarge screens style */
	@media (min-width: 1920px) {
		.gaming-lander-wrapper .section-top {
			padding-left: 320px;
			padding-right: 729px;
			padding-bottom: 180px;
		}
		.section-top p:first-child {
			padding-top: 75px;
			font-size: 24px;
		}
		.section-top h1 {
			width: 850px;
			font-size: 85px;
			line-height: 95px;
		}
		.section-top p {
			font-size: 24px;
			line-height: 28px;
		}
		.section-top .img-wrapper img {
			width: 80%;
			margin-top: -402px;
			margin-left: 600px;
		}
	}	
</style>