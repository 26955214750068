<template>
	<section class="section-why">
		<div class="main-wrapper">
			<h2>WHY <span>STREAM</span> ON <span>MANYVIDS</span>?</h2>
			<p>Manyvids is an online eco-system where all types of content creators can monetize their passion, stream amazing content and engage with their fanbase.</p>
			<div class="all-why-wrapper">
				<div>
					<div class="why-wrapper">
							<h3><font-awesome-icon class="icon" icon="angle-double-right" /> NO ALGORITHM</h3>
							<p>We're not guided by algorithms and we don't control what goes viral. Grow your community, 4 million Members are waiting to discover your!</p>
					</div>
					<div class="why-wrapper">
							<h3><font-awesome-icon class="icon" icon="angle-double-right" /> 80% PAYOUTS</h3>
							<p>Loot 80% on all tips received during your live stream.</p>
					</div>
				</div>
				<div>
					<div class="why-wrapper">
							<h3><font-awesome-icon class="icon" icon="angle-double-right" /> RECORD</h3>
							<p>Record your streams with a built-in recording tool and upload the vids to your profile with one click!</p>
					</div>
					<div class="why-wrapper">
							<h3><font-awesome-icon class="icon" icon="angle-double-right" /> OBS STUDIO</h3>
							<p>Use all of your existing OBS Scenes!</p>
				</div>
				</div>
			</div>
		</div>
		<div class="text-wrapper">
			<h2>READY TO LEVEL UP?</h2>
			<action-button class="btn-wrapper" />
		</div>
		<div class="img-wrapper">
			<img class="girl" src="../assets/images/girl-small.png"/>
			<img class="icons" src="../assets/images/small-icons.png"/>
		</div>
		<div class="bottom-bg">
		</div>
	</section>
</template>
<script>
import ActionButton from '@/components/ActionButton'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faAngleDoubleRight)

export default {
  name:"SectionWhy",
  components: { 
    ActionButton,
		'font-awesome-icon': FontAwesomeIcon
  },
}
</script>
<style scoped>
	.section-why {
		background: url("../assets/images/bg-gradient-mobile.svg");
		background-repeat: repeat-x;
		margin-top: -61px;
		overflow: hidden;
	}
	.section-why h2 {
		font-weight: bold;
		font-size: 26px;
		line-height: 40px;
		margin-top: 123px;
	}
	.section-why h2 span {
		color: #B53BFE;
	}
	.section-why > p {
		font-weight: normal;
		font-size: 18px;
		line-height: 22px;
		margin-bottom: 64px;
	}
	.section-why .why-wrapper {
		margin-bottom: 32px;
	}
	.section-why .why-wrapper h3 {
		margin-bottom: 0;
		font-weight: bold;
		font-size: 18px;
		line-height: 21px;
	}
	.section-why .why-wrapper h3 .icon {
		color: #21D6D9;
		margin-right: 10px;
	}
	.section-why .why-wrapper p {
		font-weight: normal;
		font-size: 18px;
		line-height: 22px;
		color: #BBBBBB;
		margin-top: 12px;
	}
	.section-why .text-wrapper {
			float: none;
			margin-top: auto;
	}
	.section-why h2 {
		margin-top: 64px;
    text-align: center;
		font-weight: bold;
		font-size: 30px;
		line-height: 50px;
	}
	.section-why .btn-wrapper {
		display: flex;
		justify-content: center;
	}
	.section-why .img-wrapper {
		width: 566px;
		position: relative;
	}
	.section-why .img-wrapper img.girl {
    width: 57%;
    margin-top: 65px;
    margin-left: -47px;
	}
	.section-why .img-wrapper img.icons {
		width: 14%;
    margin-bottom: 45px;
    margin-left: -30px;
	}
	.section-why .bottom-bg {
		height: 203px;
		width: 2000%;
		transform: scaleY(-1);
		background: url("../assets/images/bg-gradient-mobile.svg");
		background-repeat: repeat-x;
	}
	/* tablet style */
	@media (min-width: 768px) {
		.gaming-lander-wrapper .section-why {
			padding-left: 130px;
			padding-right: 50px;
		}
		.section-why .img-wrapper img.girl {
			margin-top: 78px;
			margin-left: -136px;
		}
		.section-why .text-wrapper {
			float: right;
			margin-top: 130px;
		}
		.section-why .bottom-bg {
			margin-left: -140px;
		}
	}
	/* small screens style */
	@media (min-width: 1024px) {
		.gaming-lander-wrapper .section-why {
			padding-left: 156px;
		}
		.section-why h2 {
			font-size: 30px;
			line-height: 43px;
		}
		.section-why p, .section-why .why-wrapper p {
			font-size: 20px;
			line-height: 24px;
		}
		.section-why .why-wrapper h3 {
			font-size: 20px;
			line-height: 24px;
		}						
	}
	/* large screens style */
	@media (min-width: 1200px) {
		.gaming-lander-wrapper .section-why {
			padding-left: 0;
			padding-right: 0;
		}
		.section-why {
			background: url("../assets/images/bg-gradient.svg");
			background-repeat: repeat-x;
		}		
		.section-why h2 {
			font-size: 35px;
			line-height: 46px;
		}
		.section-why p, .section-why .why-wrapper p {
			font-size: 22px;
			line-height: 26px;
		}
		.section-why .why-wrapper h3 {
			font-size: 23px;
			line-height: 27px;
		}
		.section-why .main-wrapper {
			width: 530px;
			margin: 0 auto;
			margin-top: 330px;
		}
		.section-why .main-wrapper .all-why-wrapper {
			display: flex;
			justify-content: space-between;
		}
		.section-why .all-why-wrapper > div {
			width: 45%;
		}
		.section-why .img-wrapper img.girl {
			width: 86%;
			margin-top: -956px;
			margin-left: -183px;
		}		
		.section-why .text-wrapper {
			float: none;
		}
		.section-why .img-wrapper img.icons {
			right: -600px;
			width: 28%;
			top: -295px;
			position: absolute;
		}
		.section-why .bottom-bg {
			height: 464px;
			background: url("../assets/images/bg-gradient.svg");
		}												
	}
	/* large screens style */
	@media (min-width: 1920px) {
		.gaming-lander-wrapper .section-why {
			padding-left: 0;
			padding-right: 0;
		}
		.section-why {
			background: url("../assets/images/bg-gradient.svg");
			background-repeat: repeat-x;
		}
		.section-why h2 {
			font-size: 40px;
			line-height: 50px;
		}
		.section-why p, .section-why .why-wrapper p {
			font-size: 24px;
			line-height: 28px;
		}
		.section-why .why-wrapper h3 {
			font-size: 26px;
			line-height: 30px;
		}
		.section-why .main-wrapper {
			width: 647px;
			margin: 0 auto;
			margin-top: 330px;
		}
		.section-why .main-wrapper .all-why-wrapper {
			display: flex;
			justify-content: space-between;
		}
		.section-why .all-why-wrapper > div {
			width: 45%;
		}
		.section-why .img-wrapper img.girl {
			width: 120%;
			margin-top: -735px;
		}
		.section-why .text-wrapper {
			float: none;
		}
		.section-why .img-wrapper img.icons {
			width: 40%;
			position: absolute;
			right: -1101px;
			top: -277px;
		}
		.section-why .bottom-bg {
			height: 464px;
			background: url("../assets/images/bg-gradient.svg");
		}
	}
</style>