<template>
    <div class="action">
        <button @click="goToGoogleForm()">
            <img src="../assets/images/controler-icon.png" />
            <span>SIGNUP</span>
        </button>
    </div>
</template>
<script>

export default {
    name:"ActionButton",
		methods: {
			goToGoogleForm() {
				location.href = "https://forms.gle/xHFjEKkLzQTKGTv79";
			}
		}
}
</script>
<style scoped>
  button{
		border: 0;
		display: flex;
		justify-content: space-between;
		width: 200px;
		height: 55px;
		background: transparent linear-gradient(281deg, var(---21d6d9) 0%, var(---4f53ee) 100%) 0% 0% no-repeat padding-box;
		background: transparent linear-gradient(281deg, #21D6D9 0%, #4F53EE 100%) 0% 0% no-repeat padding-box;
		border-radius: 90px;
		opacity: 1;
		font-weight: bold;
		font-size: 20px;
		line-height: 26px;
		font-style: normal;
		color: white;
		letter-spacing: 4.2px;
		padding: 14px 28px;
		cursor: pointer;
	}
	img {
		margin-top: 4px;
		height: 20px;
	}
	/* xlarge screens style */
	@media (min-width: 1024px) {
		button{
    width: 225px;
    height: 58px;
    font-size: 24px;
    line-height: 39px;
    padding: 10px 28px;
		}
		img {
			height: 22px;
			margin-top: 8px;
		}
	}
	/* xlarge screens style */
	@media (min-width: 1200px) {
		button{
			width: 248px;
			height: 63px;
			font-size: 26px;
			line-height: 31px;
			padding: 17px 32px;
		}
		img {
			height: 25px;
			margin-top: 3px;
		}
	}
  /* xlarge screens style */
	@media (min-width: 1920px) {
		button{
			width: 265px;
			height: 65px;
			font-size: 28px;
			line-height: 33px;
			padding: 16px 32px;
		}
		img {
			height: 25px;
		}
	}
</style>