<template>
  <section class="section-how">
    <div class="main-img-wrapper">
      <img src="../assets/images/trophy-group.svg" />
    </div>
    <div class="text-wrapper">
      <h2>HOW TO PARTICIPATE?</h2>
      <div class="how-wrapper">
        <img src="../assets/images/icon-twitter.svg" />
        <p>Post on your social media using the hashtag #MVLiveGaming, and let your fans know what they can expect during your live stream.</p>
      </div>
      <div class="how-wrapper">
        <img src="../assets/images/icon-live.svg" />
        <p>Pin your #MVLiveGaming post to the top of your Twitter & FREE FanClub feed. </p>
      </div>
      <div class="how-wrapper">
        <img src="../assets/images/icon-fanclub.svg" />
        <p>And go live anytime on June 3rd and join the gaming fun with the rest of the MV Community!</p>
      </div>
      <action-button class="btn-wrapper" />
    </div>
  </section>
</template>
<script>
import ActionButton from '@/components/ActionButton'

export default {
  name:"SectionHow",
  components: { 
    ActionButton
  },
}
</script>
<style scoped>
  .section-how {
    display: flex;
    flex-direction: column;
  }
  .section-how .main-img-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  .section-how .main-img-wrapper img {
    width: 65%;
  }
  .section-how .text-wrapper {
    margin-right: 0;
  }
  .section-how h2 {
    font-size: 30px;
    line-height: 50px;
    font-weight: bold;
    font-style: normal;
    margin-top: 58px;
  }
  .section-how div.how-wrapper {
    display: flex;
    align-items: flex-start;
  }
  .section-how .how-wrapper img {
    margin-right: 20px;
  }
  .section-how div.how-wrapper p {
    margin-top: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
  }
  .section-how .btn-wrapper {
		display: flex;
		justify-content: center;
		margin-top: 50px;
	}
	/* tablet style */
	@media (min-width: 768px) {
    .gaming-lander-wrapper .section-how {
      justify-content: center;
    }    
    .section-how {
      flex-direction: row;
      place-items: start;
    }
    .section-how .main-img-wrapper {
      justify-content: start;
      margin-top: 100px;
      width: 40%;
      margin-right: 5px;
    }
    .section-how .main-img-wrapper img {
      width: 100%;
    }
    .section-how .text-wrapper {
      width: 45%;
      margin: 0;
    }
    .section-how .btn-wrapper {
      justify-content: start;
    }
	}  
	/* small screens style */
	@media (min-width: 1024px) {
		.section-how .main-img-wrapper {
      display: block;
      width: 30%;
      margin-right: 25px;
      margin-top: 150px;
    }
    .section-how .main-img-wrapper img {
      width: 100%;
    }    
    .section-how .text-wrapper {
      margin-top: 60px;
      margin-left: 25px;
      width: 40%;
    }      
    .section-how h2 {
      font-size: 33px;
      line-height: 50px;
    }
    .section-how div.how-wrapper p {
      font-size: 22px;
      line-height: 25px;
    }		
	}
	/* large screens style */
	@media (min-width: 1200px) {
    .section-how h2 {
      font-size: 36px;
      line-height: 50px;
    }
    .section-how div.how-wrapper p {
      font-size: 20px;
      line-height: 23px;
    }			
	}
	/* large screens style */
	@media (min-width: 1920px) {
		.section-how .main-img-wrapper {
      width: 20%;
    }  
    .section-how .text-wrapper {
      width: 30%;
    }       
    .section-how h2 {
      font-size: 40px;
      line-height: 50px;
    }
    .section-how div.how-wrapper p {
      font-size: 24px;
      line-height: 28px;
    }
	}
</style>