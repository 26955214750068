<template>
	<section class="section-comment">
		<div class="img-wrapper">
			<img class="icons" src="../assets/images/avatar.png"/>
		</div>
		<div class="txt-wrapper">
			<p>"I'm super excited to spend time gaming and opening Pokemon cards with my amazing 
				community! As gamer of the year, I am ready to face any challenges that might be foolish enough to face me."</p>
			<div>
				<span>Kaley Ketchum</span>
				<span>-2022 MV Gamer of the year</span>
			</div>
		</div>
	</section>
</template>
<script>


export default {
  name:"SectionComment",
  components: { 

  },
}
</script>
<style scoped>
	.gaming-lander-wrapper .section-comment {
		padding-top: 100px;
	}
	.section-comment {
		padding: 30px 15px 130px 15px;
	}
	.section-comment p {
		text-align: center;
		font-weight: bold;
		font-size: 26px;
		line-height: 30px;
		font-style: normal;
	}
	.section-comment div {
		display: flex;
		flex-direction: column;
		text-align: center;
	}
	.section-comment div span {
		font-weight: lighter;
		font-size: 18px;
		line-height: 21px;
	}
	.section-comment div span:first-child {
		color: #B53BFE;
	}
	.section-comment .img-wrapper {
		width: 100%;
	}
	.section-comment .img-wrapper img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 68%;
	}
	/* tablet style */
	@media (min-width: 768px) {
		.section-comment p {
			text-align: center;
			padding: 0 70px;
		}
		.section-comment .img-wrapper img {
			width: 34%;
		}
	}
	/* small screens style */
	@media (min-width: 1024px) {
		.section-comment p {
			font-size: 29px;
			line-height: 34px;
		}
		.section-comment .txt-wrapper div span {
			font-size: 20px;
			line-height: 22px;
		}				
	}
	/* large screens style */
	@media (min-width: 1200px) {
		.section-comment p {
			font-size: 33px;
			line-height: 38px;
			padding: 0;
		}
		.section-comment .txt-wrapper div span {
			font-size: 21px;
			line-height: 24px;
		}
		.section-comment .txt-wrapper div span:first-child {
			margin-right: 10px;
		}
		.section-comment {
			display: flex;
		}
		.section-comment .img-wrapper img {
			width: 50%;
			margin-right: 10%;
		}
		.section-comment div.img-wrapper,
		.section-comment div.txt-wrapper {
			width: 45%;
		}
		.section-comment .txt-wrapper div {
			flex-direction: row;
			justify-content: center;
		}						
	}
	/* large screens style */
	@media (min-width: 1920px) {
		.gaming-lander-wrapper .section-comment {
			padding-bottom: 100px;
		}
		.section-comment p {
			font-size: 36px;
			line-height: 42px;
		}
		.section-comment div span {
			font-size: 36px;
			line-height: 42px;
		}
		.section-comment .txt-wrapper {
			padding-top:100px;
		}
		.section-comment .txt-wrapper div span {
			font-size: 22px;
			line-height: 25px;
		}
	}
</style>