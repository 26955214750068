<template>
	<section class="section-epic">
		<div class="wrapper">
			<div class="text-wrapper">
				<h2>BE PART OF <span>THE FIRST EPIC MV GAMING</span> EVENT</h2>
				<p>Calling all Gamers - go LIVE to play your fav games or dress like your fav gaming characters for a chance to win cash prizes!</p>
				<p>The 4 winners will be chosen based on their creative cam rooms, unicity of the live gaming stream, gaming decorations, and costumes.</p>
			</div>
			<div class="prizes-wrapper">
				<h3><font-awesome-icon class="icon" icon="angle-double-right" />1ST PLACE <span>$500</span></h3>
				<h3><font-awesome-icon class="icon" icon="angle-double-right" />2ND PLACE <span>$250</span></h3>
				<h3><font-awesome-icon class="icon" icon="angle-double-right" />3RD PLACE <span>$150</span></h3>
				<h3><font-awesome-icon class="icon" icon="angle-double-right" />4TH PLACE <span>$100</span></h3>
			</div>
		</div>
		<div class="img-wrapper">
			<img src="../assets/images/small-icons.png"/>
		</div>
		<action-button class="btn-wrapper" />
	</section>
</template>
<script>
import ActionButton from '@/components/ActionButton'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faAngleDoubleRight)

export default {
  name:"SectionEpic",
  components: { 
    ActionButton,
			'font-awesome-icon': FontAwesomeIcon
  },
}
</script>
<style scoped>
	.section-epic h2 {
		font-weight: bold;
		font-style: normal;
		font-size: 26px;
		line-height: 30px;
	}
	.section-epic h2 span {
		color: #21D6D9;
	}
	.section-epic p {
		font-weight: normal;
		font-style: normal;
		font-size: 18px;
		line-height: 21px;
	}
	.section-epic h3 {
		font-weight: bold;
		font-style: normal;
		font-size: 22px;
		line-height: 25px;
	}
	.section-epic h3 .icon {
		margin-right: 15px;
	}
	.section-epic h3 span {
		color: #21D6D9;
	}
	.section-epic .img-wrapper {
		width: 168px;
		position: relative;
		width: auto;
	}
	.section-epic .img-wrapper img {
		width: 28%;
		position: absolute;
		top: -96px;
		right: 0;
	}
	.section-epic .btn-wrapper {
		display: flex;
		justify-content: center;
		margin-top: 50px;
	}
	/* tablet and small screens style */
	@media (min-width: 768px) {
		.gaming-lander-wrapper .section-epic {
			padding-left: 80px;
			padding-right: 220px;
		}
		.section-epic h2 {
			width: 326px;
		}
		.section-epic p {
			width: 436px;
		}		
		.section-epic .btn-wrapper {
			justify-content: start;
		}
		.section-epic .img-wrapper img {
			width: 24%;
		}		
	}
	/* small screens style */
	@media (min-width: 1024px) {
		.gaming-lander-wrapper .section-epic {
			padding-left: 156px;
		}
		.section-epic h2 {
			font-size: 30px;
			line-height: 37px;
		}
		.section-epic p {
			font-size: 20px;
			line-height: 23px;
		}
		.section-epic h3 {
			font-size: 24px;
			line-height: 28px;
		}
		.section-epic .img-wrapper img {
			width: 20%;
		}				
	}
	/* large screens style */
	@media (min-width: 1200px) {
		.gaming-lander-wrapper .section-epic {
			padding-left: 233px;
		}
		.section-epic h2 {
			font-size: 35px;
			line-height: 44px;
		}
		.section-epic p {
			font-size: 22px;
			line-height: 25px;
		}
		.section-epic h3 {
			font-size: 27px;
			line-height: 31px;
		}
		.section-epic div.wrapper {
			display: flex;
		}
		.section-epic div .text-wrapper {
			width: 425px;
			margin-right: 50px;
		}
		.section-epic div .prizes-wrapper {
			padding-top: 168px;
		}		
		.section-epic .img-wrapper img {
			width: 17%;
		}								
	}
	/* xlarge screens style */
	@media (min-width: 1920px) {
		.gaming-lander-wrapper .section-epic {
			padding-left: 320px;
			padding-bottom: 280px;
		}
		.section-epic h2 {
			font-size: 40px;
			line-height: 50px;
		}
		.section-epic p {
			font-size: 24px;
			line-height: 28px;
		}
		.section-epic h3 {
			font-size: 30px;
			line-height: 35px;
		}
		.section-epic div.wrapper {
			display: flex;
		}
		.section-epic div .text-wrapper {
			width: 600px;
			margin-right: 180px
		}
		.section-epic div .prizes-wrapper {
			padding-top: 150px;
		}
		.section-epic .img-wrapper img {
			width: 13%;
		}
	}
</style>