<template>
  <div id="gaming-lander" class="gaming-lander-wrapper">
		<section-top />
		<section-epic />
		<section-how />
		<section-why />
		<section-comment />
		<section-bottom />
  </div>
</template>
<script>
import SectionTop from '../sections/SectionTop.vue'
import SectionBottom from '../sections/SectionBottom.vue'
import SectionEpic from '../sections/SectionEpic.vue'
import SectionHow from '../sections/SectionHow.vue'
import SectionWhy from '../sections/SectionWhy.vue'
import SectionComment from '../sections/SectionComment.vue'

export default {
  name:"GamingLander",
  components: { 
		SectionTop,
		SectionBottom,
		SectionEpic,
		SectionHow,
		SectionWhy,
		SectionComment,
  },
  methods: {
    OpenSite: function() {
      location.href =process.env.VUE_APP_URL_SAFE_FOR_WORK1
    }
  }
}
</script>
<style src="@mv-landers/common/lib/assets/css/common.css" lang="css"></style>
<style scoped>
	.gaming-lander-wrapper {
		border: 10px solid #21D6D9;
		background-color: #0F0F12;
		color: white;
		font-family: 'Roboto', Arial, Helvetica, sans-serif;
	}
	.gaming-lander-wrapper section {
		padding: 30px 15px;
	}
	/* tablet style */
	@media (min-width: 768px) {

	}
	/* small screens style */
	@media (min-width: 1024px) {
		
	}
	/* large screens style */
	@media (min-width: 1200px) {
		
	}
	/* large screens style */
	@media (min-width: 1920px) {
		
	}
</style>